import React from 'react'
import GenericTablePage from '@templates/Generic/Table'
import { PageProps } from 'gatsby'
import { Button } from 'gatsby-material-ui-components'
import { ButtonGroup } from '@mui/material'
import { Delete } from '@mui/icons-material'
import useApi from '@hooks/useApi'
import { GridComponentProps } from '@mui/x-data-grid-pro'
import { deleteAuditLog, getAuditLog } from '@utils/api'

const columns: GridComponentProps['columns'] = [
  {
    field: 'id',
    headerName: 'Id',
    width: 120,
    hide: true
  },
  {
    field: 'timestamp',
    headerName: 'Time',
    type: 'dateTime',
    width: 170
  },
  {
    field: 'record_type',
    headerName: 'Type',
    width: 120
  },
  {
    field: 'username',
    headerName: 'User',
    width: 150
  },
  {
    field: 'message',
    headerName: 'Message',
    width: 350,
    flex: 1
  },
  {
    field: 'ip_addr',
    headerName: 'IP',
    width: 120
  }
]

const AuditLogsPage = ({}: PageProps) => {
  const api = useApi({ apiMethod: getAuditLog })

  const handleDelete = async () => {
    await deleteAuditLog()
    setTimeout(() => api.makeRequest(), 1000)
  }

  return (
    <GenericTablePage
      title="Audit Log"
      breadcrumbs={[
        {
          title: 'Settings',
          to: '/settings/'
        },
        {
          title: 'Audit Logs'
        }
      ]}
      actions={
        <ButtonGroup
          color="primary"
          variant="outlined"
          aria-label="Host Actions"
        >
          <Button endIcon={<Delete />} onClick={handleDelete}>
            Clear Audit Log
          </Button>
        </ButtonGroup>
      }
      gridOptions={{
        checkboxSelection: false,
        columns,
        initialSort: [
          {
            field: 'timestamp',
            sort: 'desc'
          }
        ]
      }}
      api={api}
    />
  )
}

export default AuditLogsPage
